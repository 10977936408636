import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '馨茗园',
    },
  },
  {
    name: 'tiangou',
    path: '/tiangou',
    component: () => import('./view/tiangou/list'),
    meta: {
      title: '馨茗园-舔狗进化论',
    },
  },
  {
    name: 'tgwanjia',
    path: '/tgwanjia',
    component: () => import('./view/tiangou/wanjia'),
    meta: {
      title: '舔狗进化论-玩家列表',
    },
  },
  {
    name: 'event',
    path: '/event',
    component: () => import('./view/tiangou/event'),
    meta: {
      title: '舔狗进化论-抽取事件卡',
    },
  },
  {
    name: 'huati',
    path: '/huati',
    component: () => import('./view/tiangou/huati'),
    meta: {
      title: '舔狗进化论-抽取话题卡',
    },
  },
  {
    name: 'pyq',
    path: '/pyq',
    component: () => import('./view/tiangou/pyq'),
    meta: {
      title: '舔狗进化论-朋友圈',
    },
  },
  {
    name: 'center',
    path: '/center',
    component: () => import('./view/tiangou/center'),
    meta: {
      title: '舔狗进化论-个人中心',
    },
  },
  {
    name: 'zhengjian',
    path: '/zhengjian',
    component: () => import('./view/tiangou/zhengjian'),
    meta: {
      title: '舔狗进化论-证件',
    },
  },
  {
    name: 'sendPyq',
    path: '/sendPyq',
    component: () => import('./view/tiangou/sendPyq'),
    meta: {
      title: '舔狗进化论-发朋友圈',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '馨茗园-个人面板',
    },
  },
  {
    name: 'kcxList',
    path: '/kcxList',
    component: () => import('./view/kcxList'),
    meta: {
      title: '馨茗园',
    },
  },
  {
    name: 'benneng',
    path: '/benneng',
    component: () => import('./view/benneng'),
    meta: {
      title: '馨茗园',
    },
  },
  {
    name: 'sudaonan',
    path: '/sudaonan',
    component: () => import('./view/sudaonan'),
    meta: {
      title: '馨茗园',
    },
  },
  // {
  //   name: 'cart',
  //   path: '/cart',
  //   component: () => import('./view/cart'),
  //   meta: {
  //     title: '购物车',
  //   },
  // },
  // {
  //   name: 'goods',
  //   path: '/goods',
  //   component: () => import('./view/goods'),
  //   meta: {
  //     title: '商品详情',
  //   },
  // },
  {
    name: 'list',
    path: '/list',
    component: () => import('./view/list'),
    meta: {
      title: '馨茗园-人物列表',
    },
  },
  {
    name: 'card',
    path: '/card',
    component: () => import('./view/vip/card'),
    meta: {
      title: '网鱼-领取会员卡',
    },
  },
  {
    name: 'wxuser',
    path: '/wxuser',
    component: () => import('./view/vip/user'),
    meta: {
      title: '网鱼-会员中心',
    },
  },
  {
    name: 'recharge',
    path: '/recharge',
    component: () => import('./view/vip/recharge'),
    meta: {
      title: '网鱼-充值',
    },
  },
  {
    name: 'Test',
    path: '/Test',
    component: () => import('./view/vip/Test'),
    meta: {
      title: '网鱼-充值',
    },
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };