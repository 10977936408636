<template>
<div id="app" >
  <!-- <div class="main"> -->
    <router-view />
  <!-- </div> -->
  <!-- <div class="footer"> -->
    <footerView />
  <!-- </div> -->

</div>
</template>

<script>
// import Footer from './components/footer.vue';
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // Footer
}
}
</script>

<style >
body {
  font-size: 16px;
  /* background-image: url(@/assets/images/backimg.jpg); */
  /* background-color: #f8f8f8; */
  -webkit-font-smoothing: antialiased;
}
#app{
  position: absolute;
  margin: 0 auto;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: url("https://qnimg.xingminy.cn/backimg.jpg") 100%;
  background-size: 100% 100%;
}

.footer{
line-height: 10px;
height: 30px;
}
.footer{
width: 100%;
position: absolute;
bottom: 0
}
</style>