<template>
    <div class="footerFont">
      <van-row>
        <van-col>© 2009-2022 xingminy.cn 版权所有  ICP证:<a href="http://beian.miit.gov.cn" target="_blank">苏ICP备2022030661号-1</a></van-col>
      </van-row>
    </div>
</template>

<script>
import { Row, Col } from 'vant';

export default {
  name: 'footer',
  props: {
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col
  }
}
</script>

<style scoped>
.footerFont{
    position: fixed;
    right: 0px;
    top: 99%;
    font-size: 10px;
    transform: translateY(-99%);
    display: flex;
    flex-direction: column;
}
</style>