import { createStore } from 'vuex'
import {wuqiList,qingongList,fjList,lindanList,neigongList,zhaoshiList,teshuList,findObjectById} from '../database/base'

const store = createStore({
    state: {
        wxUser:{},
        userList: JSON.parse(localStorage.getItem("COMPANY_AUTH_INFO")) || [
            {id:1, name:'扶摇', gengu:40, neili:15, boji:4, minjie:6, zhaodps:4, headImg:"https://qnimg.xingminy.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220610201900.jpg", wuqi:0,fj:0,lindan:[],neigong:0,zs:0,qg:0,ts:[],ggSd:0,nlSd:0,bjSd:0,mjSd:0},
            {id:2, name:'陆承宗', gengu:36, neili:10, boji:2, minjie:3, zhaodps:2, headImg:"https://qnimg.xingminy.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220610201907.jpg", wuqi:0,fj:0,lindan:[],neigong:0,zs:0,qg:0,ts:[],ggSd:0,nlSd:0,bjSd:0,mjSd:0},
            {id:3, name:'裴矩', gengu:42, neili:18, boji:4, minjie:7, zhaodps:4, headImg:"https://qnimg.xingminy.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220610201916.jpg", wuqi:0,fj:0,lindan:[],neigong:0,zs:0,qg:0,ts:[],ggSd:0,nlSd:0,bjSd:0,mjSd:0},
            {id:4, name:'端木凤', gengu:40, neili:13, boji:3, minjie:4, zhaodps:3, headImg:"https://qnimg.xingminy.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220610201844.jpg", wuqi:0,fj:0,lindan:[],neigong:0,zs:0,qg:0,ts:[],ggSd:0,nlSd:0,bjSd:0,mjSd:0},
            {id:5, name:'燕儿', gengu:32, neili:15, boji:1, minjie:2, zhaodps:1, headImg:"https://qnimg.xingminy.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220610201854.jpg", wuqi:0,fj:0,lindan:[],neigong:0,zs:0,qg:0,ts:[],ggSd:0,nlSd:0,bjSd:0,mjSd:0},
            {id:6, name:'归蝶', gengu:32, neili:12, boji:3, minjie:5, zhaodps:3, headImg:"https://qnimg.xingminy.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220610201912.jpg", wuqi:0,fj:0,lindan:[],neigong:0,zs:0,qg:0,ts:[],ggSd:0,nlSd:0,bjSd:0,mjSd:0}
        ],
        openId: ''
    },
    getters: {
        //返回当前openid
        getCurrOpenId: (state) => {
            return state.openId
        },
        //根据用户id获取用户名
        getElementUserById: (state) => (id) => {
            let user = state.userList.find(e => e.id == id)
            if (user) {
                return user
            }
        },

        //返回用户列表
        getUserList: (state) => {
            return state.userList
        },
        getWxUser: (state) =>{
            return state.wxUser
        }

    }, 
    mutations: {
      wxUserSet(state,wxuser){
        state.wxUser = wxuser
      },
      openidSet(state,openid){
        state.openId = openid
      },
      wuqiAdd (state,[userId,wid,val]) {
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            //先判断原来有的装备，减去原来的，才加新的
            console.log("000---",user.wuqi,wid)
            if(user.wuqi!=0){
               var obj = findObjectById(wuqiList,user.wuqi)
               if(obj!=''){
                console.log("111",obj.value,user.boji)
                user.boji -=obj.value
                user.zhaodps-=obj.value
               }
            }
            user.boji +=val
            user.zhaodps+=val
            user.wuqi = wid
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      fangjuAdd (state,[userId,wid,gengu,boji]) {
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            if(user.fj!=0){
                var obj = findObjectById(fjList,user.fj)
                if(obj!=''){
                 user.boji -=obj.boji
                 user.zhaodps-=obj.boji
                 user.gengu -=obj.gengu
                }
            }
            user.boji +=boji
            user.zhaodps+=boji
            user.gengu +=gengu
            user.fj = wid
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      lindanAdd (state,[userId,widarry]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            if(user.lindan.length!=0){
                for (let i = 0; i < user.lindan.length; i++) {
                    const element = user.lindan[i];
                    // console.log("element===",element)
                    var obj = findObjectById(lindanList,element)
                    if(obj!=''){
                        user.boji -=obj.boji
                        user.zhaodps-=obj.boji
                        user.gengu -=obj.gengu
                        user.neili -=obj.neili
                    }
                }
               
            }
            for (let i = 0; i < widarry.length; i++) {
                const element = widarry[i];
                // console.log("element===",element)
                let obj = findObjectById(lindanList,element)
                if(obj!=''){
                    user.boji +=obj.boji
                    user.zhaodps+=obj.boji
                    user.gengu +=obj.gengu
                    user.neili +=obj.neili
                }
            }
            user.lindan = widarry
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      neigongAdd(state,[userId,wid,gengu,neili,minjie]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            if(user.neigong!=0){
                var obj = findObjectById(neigongList,user.neigong)
                if(obj!=''){
                    user.minjie -=obj.minjie
                    user.gengu -=obj.gengu
                    user.neili -=obj.neili
                }
            }
            user.minjie +=minjie
            user.gengu +=gengu
            user.neili +=neili
            user.neigong = wid
        }
      },
      zhaoshiAdd(state,[userId,wid,val]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            if(user.zs!=0){
                var obj = findObjectById(zhaoshiList,user.zs)
                console.log("obj==",obj)
                if(obj!=''){
                    user.zhaodps -=obj.value
                }
            }
            user.zhaodps +=val
            user.zs =wid
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      qinggongAdd(state,[userId,wid,val]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            if(user.qg!=0){
                var obj = findObjectById(qingongList,user.qg)
                if(obj!=''){
                    user.minjie -=obj.value
                }
            }
            user.minjie +=val
            user.qg =wid
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      teshuToolAdd(state,[userId,widarry]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            if(user.ts.length>0){
                for (let i = 0; i < user.ts.length; i++) {
                    const element = user.ts[i];
                    var obj = findObjectById(teshuList,element)
                    if(obj!=''){
                        user.boji -=obj.boji
                        user.zhaodps -=obj.boji
                        user.gengu -=obj.gengu
                        user.neili -=obj.neili
                    }
                }
            }
            for (let i = 0; i < widarry.length; i++) {
                const element = widarry[i];
                let obj = findObjectById(teshuList,element)
                if(obj!=''){
                    user.boji +=obj.boji
                    user.zhaodps+=obj.boji
                    user.gengu +=obj.gengu
                    user.neili +=obj.neili
                }
            }
            user.ts = widarry
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      genguSdAdd(state,[userId,val]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            parseInt
            user.gengu +=parseInt(val)
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      neiliSdAdd(state,[userId,val]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            user.neili +=parseInt(val)
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      bojiSdAdd(state,[userId,val]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            user.boji +=parseInt(val)
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      },
      minjieSdAdd(state,[userId,val]){
        let user = state.userList.find(e => e.id == userId)
        if (user) {
            user.minjie +=parseInt(val)
        }
        localStorage.setItem("COMPANY_AUTH_INFO", JSON.stringify(state.userList))
      }
    }
  })

export { store };
