
const qingongList = [
    { id:0,name: '无', value: 0 },
    { id:1,name: '幻魔身法', value: -4 },
    { id:2,name: '扶桑遁术', value: 0 },
    { id:3,name: '白驹过隙', value: 7 },
    { id:4,name: '飞鸾泛月', value: 2 },
    { id:5,name: '醉影望月步', value: 3 },
    { id:6,name: '凤舞九天', value: 0 },
    { id:7,name: '神踪千幻', value: 0 },
];
const wuqiList = [
    { id:0,name: '无', value: 0 },
    { id:1,name: '天泽剑', value: 3 },
    { id:2,name: '大典太光世', value: 4 },
    { id:3,name: '鬼丸国纲', value: 4 },
    { id:4,name: '太古遗音琴', value: 4 },
    { id:5,name: '血刀', value: 4 },
    { id:6,name: '鬼王枪', value: 3 },
    { id:7,name: '轩辕剑', value: 6 },
    { id:8,name: '魔剑', value: 5 },
    { id:9,name: '苍狼刀', value: 4 },
    { id:10,name: '穿云剑', value: 2 },
    { id:11,name: '方天画戟', value: 4 },
    { id:12,name: '紫金葫芦', value: 2 },
];
const fjList = [
    { id:0,name: '无', gengu: 0, boji:0 },
    { id:1,name: '青阳太昭袍', gengu: 5, boji:0 },
    { id:2,name: '彩凤罗裳', gengu: 4, boji:0 },
    { id:3,name: '九品莲台', gengu: 5, boji:0 },
    { id:4,name: '天子龙袍', gengu: 5, boji:1 },
    { id:5,name: '天蚕宝衣', gengu: 0, boji:0 },
    { id:6,name: '替身纸偶', gengu: 0, boji:0 },
    { id:7,name: '软猬甲', gengu: 0, boji:0 },
    { id:8,name: '八卦阵', gengu: 0, boji:0 },
    { id:9,name: '护心镜', gengu: 0, boji:0 },
]
const lindanList = [
    // { id:0,name: '无', gengu: 0, neili:0, boji:0 },
    { id:1,name: '造化丹', gengu: 10, neili:0, boji:0 },
    { id:2,name: '洗髓丹', gengu: 5, neili:0, boji:0 },
    { id:3,name: '大还丹', gengu: 0, neili:10, boji:0 },
    { id:4,name: '小还丹', gengu: 0, neili:5, boji:0 },
    { id:5,name: '龙象膏', gengu: 0, neili:0, boji:4 },
]
const neigongList = [
    { id:0, name: '无', gengu: 0, neili: 0, minjie: 0 },
    { id:1, name: '万宗归流', gengu: 8, neili: 8, minjie: 0 },
    { id:2, name: '吐纳心法', gengu: 3, neili: 3, minjie: 0 },
    { id:3, name: '冰肌玉骨功', gengu: 6, neili: 6, minjie: 0 },
    { id:4, name: '六道轮回', gengu: 9, neili: 9, minjie: 0 },
    { id:5, name: '凤鸾秘录', gengu: 8, neili: 7, minjie: 1 },
    { id:6, name: '唯我独尊功', gengu: 8, neili: 8, minjie: 0 },
    { id:7, name: '浩然正气', gengu: 8, neili: 8, minjie: 0 },
    { id:8, name: '白首太玄经', gengu: 12, neili: 12, minjie: 0 },
    { id:9, name: '长生天神力', gengu: 6, neili: 6, minjie: 0 },
    { id:10, name: '血神经', gengu: 7, neili: 7, minjie: 0 },
    { id:11, name: '长生醉决', gengu: 4, neili: 4, minjie: 0 },
    { id:12, name: '葵花神功', gengu: 6, neili: 6, minjie: 2 },
    { id:13, name: '万毒秘籍', gengu: 5, neili: 5, minjie: 0 },
]
const zhaoshiList = [
    { id:0, name: '无', value: 0 },
    { id:1, name: '止戈武律', value: 9 },
    { id:2, name: '圣王剑法', value: 3 },
    { id:3, name: '灵犀剑指', value: 3 },
    { id:4, name: '扶桑剑术', value: 3 },
    { id:5, name: '千佛如来掌', value: 2 },
    { id:6, name: '弱水柔易', value: 0 },
    { id:7, name: '六祸禁式', value: 7 },
    { id:8, name: '太坟八阙', value: 5 },
    { id:9, name: '血魔刀法', value: 5 },
    { id:10, name: '苍狼噬日刀', value: 6 },
    { id:11, name: '寒冰真气', value: 5 },
    { id:12, name: '醉八仙', value: 3 },
    { id:13, name: '五帝龙拳', value: 13 },
    { id:14, name: '净世琉璃身', value: 0 },
    { id:15, name: '神农功', value: 0 },
    { id:16, name: '凤凰于飞', value: 6 },
    { id:17, name: '无相神针', value: 3 },
    { id:18, name: '赤练神掌', value: 3 },
]
const teshuList = [
    // { id:0, name: '无', gengu: 0, neili: 0, boji:0 },
    { id:1, name: '天心配', gengu: 3, neili: 0, boji:0 },
    { id:2, name: '传国玉玺', gengu: 0, neili: 3, boji:0 },
    { id:3, name: '大周北境军事图', gengu: 0, neili: 0, boji:1 },
    { id:4, name: '镇国九鼎', gengu: 0, neili: 0, boji:1 },
];

const tiangouList = [
    { id:1, name: '学员1', xm: '刘潇洒', headimg: 'https://qnimg.xingminy.cn/tg_xy2.jpg' ,xyimg:'https://qnimg.xingminy.cn/xy1_20220725235815.jpg'},
    { id:2, name: '学员2', xm: '王帅气', headimg: 'https://qnimg.xingminy.cn/tg_xy6.jpg' ,xyimg:'https://qnimg.xingminy.cn/xy2_20220725235825.jpg'},
    { id:3, name: '学员3', xm: '徐高冷', headimg: 'https://qnimg.xingminy.cn/tg_xy3.jpg' ,xyimg:'https://qnimg.xingminy.cn/xy3_20220725204748.jpg'},
    { id:4, name: '学员4', xm: '方美丽', headimg: 'https://qnimg.xingminy.cn/tg_xy4.jpg' ,xyimg:'https://qnimg.xingminy.cn/xy4_20220725204757.jpg'},
    { id:5, name: '学员5', xm: '吴天真', headimg: 'https://qnimg.xingminy.cn/tg_xy5.jpg' ,xyimg:'https://qnimg.xingminy.cn/xy5_20220725204804.jpg'},
    { id:6, name: '学员6', xm: '罗玉洁', headimg: 'https://qnimg.xingminy.cn/tg_xy1.jpg' ,xyimg:'https://qnimg.xingminy.cn/xy6_20220725235835.jpg'},
    { id:7, name: 'DM', xm: 'DM', headimg: 'https://qnimg.xingminy.cn/tg_xy6.jpg' },
]

var findObjectById = function(list,id){
    for (let i = 0; i < list.length; i++) {
      var element = list[i];
      if(list[i].id==id){
        return element
      }
    }
    return ''
}

var findObjectByName = function(list,name){
    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if(list[i].name===name){
            return element
        }
    }
    return ''
}

export {wuqiList,qingongList,fjList,lindanList,neigongList,zhaoshiList,teshuList,tiangouList, findObjectByName, findObjectById }