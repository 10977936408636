import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router';
import footerView from './components/footer.vue';
import VueQr from 'vue-qr'
import 'vant/lib/index.css';
import { store } from "./store/index"

const app = createApp(App);
app.use(router)
app.component("vue-qr",VueQr)
app.component("footerView", footerView);
app.use(store)
app.mount('#app')
